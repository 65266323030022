import React from "react";

import Error from "../../components/Error";

const ErrorPage = () => {
  return (
    <>
      <Error />
    </>
  );
};
export default ErrorPage;
